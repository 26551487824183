import { Container, Row, Col, Toast } from 'react-bootstrap'
import { Item } from './main'
import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class Gift extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            showgift: false,
            show: false,
            show1: false,
            copy: null
        }
    }
    render() {
        let { copy, showgift } = this.state
        let { cmain, content, caption, color, bg, ccaption, langsung, reza,alamat,rinda,prep,silmi,yumna } = this.props
        if (langsung) {
            showgift = langsung
        }
        let style={}
        let stylejudul ={}
        let stylecontent={}
        let border={}
        if (rinda){
            stylejudul={fontSize:'32px',color:'white'}
            stylecontent={fontSize:'16px',color:'white'}
        }else if(silmi){
            stylejudul={fontSize:'32px',color:'black'}
            stylecontent={fontSize:'16px',color:'black'}
            border={borderColor:'black'}
        }else if(yumna){
            stylejudul={fontSize:'32px',color:'white'}
            stylecontent={fontSize:'16px',color:'white'}
            border={borderColor:cmain}
        }
        if(prep){
            stylejudul={fontSize:'32px',color:'black'}
            stylecontent={fontSize:'16px',color:'black'}
            border={borderColor:'black'}
        }
        if (reza) {
            return (
                <Container fluid
                    className={`text-center aulia-wedding py-3 px-3 ${!showgift ? 'trsnone' : 'trs'}`} >
                    <Item>
                        <Col className="py-3 px-md-5 mx-2" 
                        style={{ backgroundColor: cmain, 
                        borderRadius: '10px', border: '2px solid white',...border }}>
                            <h1 style={stylejudul}>
                                Wedding Gift
                            </h1>
                            {/* <Item>
                            <Col xs={8} md={4}>
                              <img src='https://i.ibb.co/5K53bBy/QR-BCA.png' className='p-2 w-100 img-fluid'/>
                            </Col>
                          </Item> */}
                            <Item>
                                {
                                    content.map((v, i) => {
                                        return (
                                            <>
                                                <p className="text-center w-100" style={stylecontent}>
                                                    {v.bank} - {v.norek} <br />
                                                    ({v.nama})
                                                </p>

                                                <Col xs={12} className="text-center">
                                                    <Item>

                                                        <Toast onClose={() => this.setState({ copy: null })}
                                                            show={copy === i} delay={3000} autohide
                                                            className="text-center" >
                                                            <Toast.Body>Copied!</Toast.Body>
                                                        </Toast>
                                                    </Item>
                                                </Col>

                                                <Col xs={12} className="text-center">
                                                    <Item>
                                                        <CopyToClipboard text={v.norek}>
                                                            <Col xs={8} md={5} className='btn button mb-5 mt-1'
                                                                onClick={(e) => {
                                                                    this.setState({ copy: i })

                                                                }}>
                                                                Salin No. Rekening
                                                            </Col>
                                                        </CopyToClipboard>
                                                    </Item>
                                                </Col>
                                            </>
                                        )
                                    })

                                }


                                <p className="text-center w-100" style={{color:rinda?'white':'',...stylecontent}}>
                                    Alamat Pengiriman Kado
                                </p>
                                <p className="text-center w-100 px-3" style={{color:rinda?'white':'',...stylecontent}}>
                                    <b>
                                        {alamat}
                                    </b>
                                </p>
                            </Item>
                        </Col>
                    </Item>

                    <Item>
                        <Col>
                        </Col>

                    </Item>

                </Container>

            )
        }
        else {
            return (
                <>

                    {
                        langsung ? false : (
                            <Container className="py-3" fluid >
                                <Item>
                                    <Col
                                        onClick={() => this.setState({ showgift: !showgift })}
                                        xs={10} md={4}
                                        style={{
                                            border: `2px solid #86835dff`,
                                            borderRadius: '10px'
                                        }}
                                        className="p-2 mx-md-2 mt-3 mt-md-0">
                                        <Item>
                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                style={{ width: "10%", height: '10%' }} />
                                            <p className="mb-0 my-auto ml-3" style={{ color: color }}>
                                                <b>Send Gift (click here)</b>
                                            </p>
                                        </Item>
                                    </Col>
                                </Item>
                            </Container>
                        )
                    }

                    <Container
                        className={`text-center mt-3 aulia-wedding py-3 px-3 ${!showgift ? 'trsnone' : 'trs'}`} >
                        <h1 style={{ color: '#86835dff' }} className="w-100 text-center py-2">
                            Wedding Gift
                    </h1>
                        <p className="py-2 w-100 text-center" style={{ color: ccaption ? ccaption : color }}>
                            {caption}
                        </p>
                        {
                            content.map((v, i) => {
                                return (
                                    <>
                                        <Row className="m-2 py-3" style={{ backgroundColor: bg, borderRadius: '10px' }}>
                                            <p className="text-center w-100" style={{ color: color }}>
                                                {v.bank}  <br />
                                        Account No. {v.norek} <br />
                                        a.n {v.nama}
                                            </p>
                                            <Col xs={12} className="text-center">
                                                <Item>
                                                    <Toast onClose={() => this.setState({ copy: null })}
                                                        show={copy === i} delay={3000} autohide
                                                        className="text-center" >
                                                        <Toast.Body>Copied!</Toast.Body>
                                                    </Toast>
                                                </Item>
                                            </Col>
                                            <Col xs={12}>

                                                <CopyToClipboard text={v.norek}>
                                                    <Col xs={12} md={5} className='btn button mt-1'
                                                        onClick={(e) => {
                                                            this.setState({ copy: i })
                                                        }}>
                                                        Copy Account No.
                                                    </Col>
                                                </CopyToClipboard>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            })
                        }

                    </Container>

                </>
            )
        }
    }

}
